









































import Vue from 'vue';

export default Vue.extend({
  props: {
    pricingPlans: {
      type: Array,
      required: true,
    },
  },
});
